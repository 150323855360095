.reviews__header {
  border-bottom: 1px solid #f4f7f5;
}

.gauge__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.gauge__container p {
  line-height: 21px;
  font-size: 16px;
  color: #25181d;
}
@media (max-width: 47.99em) {
  .gauge__container p {
    font-size: 14px;
  }
}
.gauge__background {
  display: flex;
  align-items: center;
  height: 10px;
  width: 130px;
  border: 1px solid #3fa534;
  border-radius: 5px;
  background-color: #e1eee1;
}
@media (max-width: 47.99em) {
  .gauge__background {
    width: 100px;
  }
}
@media (max-width: 23.4275em) {
  .gauge__background {
    width: 80px;
  }
}
.gauge__fill {
  height: 10px;
  width: 0;
  border-radius: 5px;
  background-color: #3fa534;
  transition: width 0.5s ease-out;
}
.gauge__flll--one {
  width: 20%;
}
.gauge__fill--onehalf {
  width: 30%;
}
.gauge__fill--two {
  width: 40%;
}
.gauge__fill--twohalf {
  width: 50%;
}
.gauge__fill--three {
  width: 60%;
}
.gauge__fill--threehalf {
  width: 70%;
}
.gauge__fill--four {
  width: 80%;
}
.gauge__fill--fourhalf {
  width: 90%;
}
.gauge__fill--five {
  width: 100%;
}

.rating__stars svg {
  margin: 0 1px;
  width: 19px;
  height: 18px;
}

@media (max-width: 47.99em) {
  .rating__stars svg {
    width: 14px;
    height: 13px;
  }
}
.animation__star {
  animation-duration: 0.2s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.animation__star--reviews {
  animation-name: fill-reviews-stars;
}
.animation__star--defaqto {
  animation-name: fill-defaqto-stars;
}
svg:nth-child(2) path.animation__star--defaqto,
svg:nth-child(2) path.animation__star--reviews {
  animation-delay: 0.4s;
}
svg:nth-child(3) path.animation__star--defaqto,
svg:nth-child(3) path.animation__star--reviews {
  animation-delay: 0.6s;
}
svg:nth-child(4) path.animation__star--defaqto,
svg:nth-child(4) path.animation__star--reviews {
  animation-delay: 0.8s;
}
svg:nth-child(5) path.animation__star--defaqto,
svg:nth-child(5) path.animation__star--reviews {
  animation-delay: 1s;
}
@keyframes fill-reviews-stars {
  0% {
    fill: #e0e0e0;
  }
  to {
    fill: #00d265;
  }
}
@keyframes fill-defaqto-stars {
  0% {
    fill: #e0e0e0;
  }
  to {
    fill: #e7772f;
  }
}
