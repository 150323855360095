.table {
  color: var(--black) !important;
}

td {
  border: 0;
  cursor: initial;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor !important;
}

.summary-container {
  tr th:nth-child(even),
  tr td:nth-child(even) {
    text-align: end;
  }

  td:nth-child(even) {
    border: 1px !important;
  }

  .row-expand-slide-exit-active,
  .row-expand-slide-appear-active {
    transition: none !important;
  }

  .row-expansion-style {
    padding: 0 !important;
  }
}

.sub-company-table {
  .table {
    text-align: center;
  }

  tr th:first-child,
  tr td:first-child {
    text-align: start;
  }
}

.iframe-box {
  position: relative;
  width: 95%;
  height: 95%;
  margin: auto;
}
.iFrame-style {
  width: 100%;
  height: 100%;
  padding-top: 38px;
  padding-inline: 20px;
}
.close-btn {
  position: absolute;
  top: 0px;
  right: 16px;
  z-index: 99999;
  font-size: 24px;
  color: var(---white);
  &:hover {
    color: var(--black);
  }
}
