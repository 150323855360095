.external_link {
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  font-size: 12px !important;
}

.external_link_icon {
  width: 12px;
  height: 12px;
  font-size: 12px !important;
  margin-left: 5px;
}
