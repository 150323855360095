.drop-down {
  background-color: var(--tapestry);
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-tapestry {
  background-color: var(--tapestry) !important;
  color: #fff !important;
}

.bg-tapestry:hover {
  color: hsl(336, 35%, 76%) !important;
}

.picklist-item {
  padding: 0.05rem 0.75rem;
  width: 100%;
  word-break: keep-all !important;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.picklist-item p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media only screen and (max-width: 480px) {
  .picklist-item {
    height: 2.5rem;
  }
}
