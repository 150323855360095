.address-form-wrapper {
  width: 45%;
  margin: auto;
}

@media only screen and (min-width: 481px) and (max-width: 991px) {
  .address-form-wrapper {
    width: 60%;
    padding-top: 4rem;
  }
}

@media only screen and (max-width: 480px) {
  .address-form-wrapper {
    width: 100%;
    padding: 2rem 1rem 0;
  }
}
