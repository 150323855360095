.driver-license-page-wrapper {
  width: 50%;
  margin: auto;
}

.input-form {
  background-color: var(--tapestry);
  border: none;
  color: var(--white) !important;
  height: 2.9rem;
  font-size: 1rem;
  font-family: "GreycliffCf-Medium";
}
.input-form:focus {
  background-color: var(--tapestry);
  outline: none !important;
}
.input-form-textarea {
  background-color: inherit !important;
  height: 2.9rem;
  border: 1px solid var(--hopbush);
  color: var(--white);
  font-size: 1rem;
  font-family: "GreycliffCf-Medium";
}
.input-form-style {
  background-color: var(--tapestry) !important;
  border: none;
  height: 2.9rem;
  color: var(--white) !important;
  font-size: 1rem;
  font-family: "GreycliffCf-Medium";
}
select ::-ms-expand {
  display: none !important;
}
.select-box {
  position: relative;
}
.select-box::after {
  content: url("../assest/arrowDN.png");
  font: 25px "Consolas", monospace;
  color: var(--white);
  font-family: "GreycliffCF-Bold";
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}
.select-box:focus-within::after {
  content: url("../assest/arrowDN.png");
  font: 25px "Consolas", monospace;
  color: var(--white);
  font-family: "GreycliffCF-Bold";

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  right: 11px;
  top: 5px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

select {
  width: 150px;
  height: 30px;
  padding: 5px;
  color: var(--white) !important;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder {
  color: var(--white);
}

.btn-validate {
  background-color: var(--cosmic);
  text-align: center;
  color: var(--white);
  font-size: 0.85rem;
  font-family: "GreycliffCf-Medium";
  border-radius: 20px;
  padding: 0.4rem 0.9rem !important;
  border: none;
  transition: background-color 0.2s ease-in-out;
}
.btn-validate:hover {
  background-color: var(--violet-red) !important;
}
.btn-validate:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.protect-input {
  font-family: "GreycliffCf-Medium";
  color: var(--white);
  font-size: 0.9rem;
  padding: 1rem 0;
}

.gender-date-wrapper {
  display: flex;
  justify-content: space-between;
}

.driver-license-wrapper {
  margin-top: 0;
}

.name-validate-error {
  color: var(--koromiko);
  font-family: "GreycliffCF-Bold", Arial, Helvetica, sans-serif;
  margin-top: 1.3rem;
  text-align: center;
}

.validate-error-message {
  color: var(--koromiko);
  font-family: "GreycliffCF-Bold", Arial, Helvetica, sans-serif;
  margin-top: 1.3rem;
}

.text-koromiko {
  color: var(--koromiko);
  font-family: "GreycliffCF-Medium", Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 992px) {
  .driver-license-page-wrapper {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .name-validate-error,
  .validate-error-message {
    font-size: 14px;
  }
  .input-form,
  .input-form-style {
    height: 2.5rem;
  }

  .driver-license-page-wrapper {
    padding: 2rem 1rem 0;
    width: 100%;
  }
}
