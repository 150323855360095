.selection-cell,
.selection-cell-header {
  display: none !important;
}

.search-label {
  display: block !important;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
}

.show.react-bs-table-sizePerPage-dropdown ul:last-child {
  display: block;
}

.pagination {
  justify-content: flex-end !important;
}

#pageDropDown {
  background: transparent;
  border: 1px solid #fff;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  color: inherit;
}

.table-hover > tbody > tr:hover > * {
  color: #212529;
}
