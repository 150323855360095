.company-detail {
  padding: 20px;
  color: var(--white);
}
.overview {
  padding: 20px;
  color: var(--white);
}

.type-inc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.overview-heading {
  font-size: 2em;
  font-family: "GreycliffCF-ExtraBold";
  color: var(--white);
}

.overview p,
.overview div {
  padding: 0;
  margin: 0;
}

.overview .info-wrap {
  padding: 20px 0;
}

.detail {
  font-size: 1.3em;
  text-transform: capitalize;
}

.detail-title {
  color: var(--alto);
  font-family: "GreycliffCF-Bold";
}
