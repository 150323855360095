.badge_feature {
  background: #3fa534;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.badge_excluded {
  background: #fff;
  border: 2px solid #3fa534;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3fa534;
}

.badge_excluded_sm {
  background: #fff;
  border: 2px solid #3fa534;
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3fa534;

  svg {
    margin: 3px;
  }
}

.badge_feature_sm {
  background: #3fa534;
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  svg {
    margin: 3px;
  }
}

.badge_light {
  background: #f4f7f5;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge_light_sm {
  background: #fff;
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 3px;
  }
}

.badge_title {
  line-height: 16px;
  letter-spacing: 0;
  color: #242626;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  margin-top: 5px;
}

.badge_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sm_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.excess_badge {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 24px;
  margin: 8px auto 0;
  background: #ebebeb;
  border-radius: 100px;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
}

.card_title {
  font-size: 18px;
  line-height: 22px;
  color: #3fa534;
  font-weight: bold;
}

.accordion__button {
  display: flex;
  align-items: center;
}

.alert {
  background-color: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
  padding: 6px !important;
  text-align: center;
}

.text-green {
  color: #3fa534;
}

p {
  font-size: 14px;
}

.moreinfo-your-info__icon {
  padding: 11px 20px 12px;
}

.card_modal_title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  color: #3fa534;
  font-weight: 400;
  flex: 1;
  display: flex;
  margin: 0;
}

.slideout__close {
  background: none;
  border: 0;
  height: 53px;
  z-index: 1;
}

.card_modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  max-width: 488px;
  z-index: 1000000;
}

.card_modal_header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 16px 0 rgb(0 0 0 / 5%);
  z-index: 1;
  max-width: 488px;
  padding-inline: 20px;
}

.card_modal_scroll_area {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.card_modal_content {
  padding: 20px;
  padding-bottom: 80px;
  padding-top: 73px;
}

.card_modal_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1eee1;
  background: #fff;
  z-index: 1;
}

.btn-large {
  min-width: 100px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none !important;
}

.drawer_header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.drawer_container_bg {
  background: #f4f7f5;
}

.border_top_light {
  border-top: 1px solid #e1eee1;
}

.border_bottom_light {
  border-bottom: 1px solid #e1eee1;
}

.content_container {
  padding-bottom: 120px !important;
}
