.login-wrapper {
  max-width: 320px;
  margin: auto;
  padding: 145px 0;
}
.login-wrapper h1 {
  font-family: "GreycliffCF-Bold";
  color: var(--white);
  font-size: 38px;
  text-align: center;
  margin-top: 78px;
  margin-bottom: 72px;
}
.login-wrapper .message {
  color: var(--rajah);
  text-align: center;
  display: block;
  margin-top: 42px;
  font-size: 15px;
  font-family: "GreycliffCF-Bold";
}
.login-wrapper button {
  margin: 0 !important;
}
.img-wrapper {
  background-position: center;
  width: 256px;
  min-height: 100px;
  max-height: 200px;
  margin: 0 auto;
  position: relative;
  left: unset;
  /* text-align: center; */
}

.login-icons-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 6.5rem 0;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.login-icon-wrapper {
  width: 55px;
}

.login-icon-wrapper > img {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .login-wrapper {
    max-width: 330px;
    padding: 3.7rem 10px 10px;
  }
  .login-icons-wrapper {
    margin: 3rem 0 0;
  }
  .login-wrapper .message {
    font-size: 14px;
  }
}
