.bottom-nav-wrapper {
  width: 100%;
  height: 100px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(238, 122, 163, 0.5);
}

.box_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  height: 100%;
  margin: auto;
}

.box_wrapper {
  width: 100%;
  height: 100%;
  max-width: 64px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.box {
  width: 100%;
  height: 100%;
  max-width: 64px;
  max-height: 64px;
  color: var(--black);
  background-color: var(--lily);
  border: 1px solid rgba(248, 248, 248, 0.5);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 570px) {
  .box_container {
    justify-content: space-between;
    width: 94%;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .box_container {
    justify-content: space-between;
    width: 94%;
    margin: auto;
  }
  .box_wrapper:first-child {
    margin-left: 0;
  }

  .box_wrapper:last-child {
    margin-right: 0;
  }

  .bottom-nav-wrapper {
    width: 94%;
    height: 90px;
    left: 50%;
    bottom: 0.313rem;
    transform: translateX(-50%);
    border-radius: 1.7rem;
    padding: 4px;
  }
}

@media screen and (min-width: 481px) and (max-width: 992px) {
  .image-wrapper {
    max-width: 75px;
  }
  .box_wrapper {
    max-width: 75px;
  }
  .box {
    max-width: 75px;
    max-height: 75px;
  }
}
