.content_box {
  height: calc(100% - 80px);
}

.loading_box {
  height: calc(100% - 65px);
}

.content_box_icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}
