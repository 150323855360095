.home_container {
  width: 40%;
  margin: auto;
}
.home-wrapper {
  width: 100%;
  background-color: #fff3;
  border-radius: 8px;
  height: 590px;
  margin: 1.2rem 0 1.2rem 0;
  position: relative;
  z-index: 1;
}
.home-wrapper .title-wrapper {
  padding: 16px 32px;
  text-align: center;
  align-items: center;
}
.home-wrapper .title-wrapper h1 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiB2ZXJzaW9uPSIxLjEiPjxwYXRoIGQ9Im02Ljk0IDAuNWMtMC4yNCAwLTAuNDQgMC4yLTAuNDQgMC40NHYxLjI2Yy0wLjUgMC4xNC0xLjA1IDAuMzUtMS41MyAwLjY1bC0wLjkxLTAuOTFjLTAuMTYtMC4xOC0wLjQ2LTAuMTktMC42MiAwbC0xLjUgMS41Yy0wLjE4IDAuMTctMC4xOCAwLjQ2IDAgMC42MmwwLjkxIDAuOTFjLTAuMjg0IDAuNDgtMC41IDEtMC42NSAxLjUzaC0xLjI2Yy0wLjI0IDAtMC40NCAwLjItMC40NCAwLjQ0djIuMTJjMCAwLjI1IDAuMTkgMC40NCAwLjQ0IDAuNDRoMS4yNmMwLjE0IDAuNTQgMC4zNiAxLjA1IDAuNjUgMS41M2wtMC45MSAwLjkxYy0wLjE4IDAuMTctMC4xOCAwLjQ1IDAgMC42MmwxLjUgMS41YzAuMTggMC4xOCAwLjQ2IDAuMTggMC42MiAwbDAuOTEtMC45MWMwLjQ4IDAuMjg1IDEgMC41IDEuNTMgMC42NXYxLjI2YzAgMC4yNSAwLjIgMC40NCAwLjQ0IDAuNDRoMi4xMmMwLjI0IDAgMC40NS0wLjIgMC40NC0wLjQ0di0xLjI2YzAuNTQtMC4xNCAxLjA1LTAuMzYgMS41My0wLjY1bDAuOTEgMC45MWMwLjE3IDAuMTggMC40NSAwLjE4IDAuNjIgMGwxLjUtMS41YzAuMTgtMC4xNyAwLjE4LTAuNDUgMC0wLjYybC0wLjkxLTAuOTFjMC4yOS0wLjQ4IDAuNS0xIDAuNjUtMS41M2gxLjI2YzAuMjQgMCAwLjQ1LTAuMiAwLjQ0LTAuNDR2LTIuMTJjMC0wLjI0LTAuMi0wLjQ0LTAuNDQtMC40NGgtMS4yNmMtMC4xNC0wLjU0LTAuMzYtMS4wNS0wLjY1LTEuNTNsMC45MS0wLjkxYzAuMTgtMC4xNyAwLjE4LTAuNDUgMC0wLjYybC0xLjUtMS41Yy0wLjE3LTAuMTgtMC40NS0wLjE4LTAuNjIgMGwtMC45MSAwLjkxYy0wLjQ4LTAuMjktMS0wLjUtMS41My0wLjY1di0xLjI2YzAtMC4yNC0wLjItMC40NC0wLjQ0LTAuNDRoLTIuMTJ6bTEuMDYgNGEzLjUgMy41IDAgMCAxIDMuNSAzLjUgMy41IDMuNSAwIDAgMSAtMy41IDMuNSAzLjUgMy41IDAgMCAxIC0zLjUgLTMuNSAzLjUgMy41IDAgMCAxIDMuNSAtMy41eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPgo=);
    background-repeat: no-repeat;
    display: block;
    flex-grow: 1;
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    background-size: 32px;
    background-position: 14px 12px;
    padding: 16px 8px 16px 60px;
    height: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: grab;
    text-align: left;
}
.home-wrapper .title-wrapper svg {
  margin-right: 16px;
}
.home-wrapper h1 {
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1.4px;
  margin-bottom: 0;
}
.panel-wrapper {
    max-width: 1500px;
}
.icon-wrapper {
  width: 94%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
}
.icons {
  width: 22%;
  color: var(--white);
  margin: 10px 4px;
    text-align: center;
}
.image-wrapper {
  margin: auto;
}
.icons-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  opacity: 0.8;
  border-radius: 1rem;
  min-height: 50.94px;
}
.icons-img {
  object-fit: contain;
  opacity: 0.8;
  border-radius: 1rem;
}
.inner {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 1.3;
}

.icon-link {
  color: var(--white);
  text-decoration: none;
}

.icon-link:hover {
  color: var(--white);
  text-decoration: none;
}

.icon-link p {
    font-size: 12px;
    margin-top: 3px;
}

/* start slider navigation icon styles  */
.arrow_icons_container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9998;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.icon1 {
  color: var(--white);
  font-size: 1.6rem;
  cursor: pointer;
}
.icon2 {
  color: var(--white);
  font-size: 1.3rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}
.icon3 {
  color: var(--white);
  font-size: 1.6rem;
  cursor: pointer;
}
/* end slider navigation icon styles  */

.swiper-pagination-bullets.swiper-pagination-horizontal {
  visibility: visible;
}
.panel {
    margin: 16px;
}
@media only screen and (min-width: 993px) {
  .home_container {
    width: 40%;
    max-width: 320px;
    min-width: 310px;
  }
  .home-wrapper {
    margin: 0;
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 992px) {
  .home_container {
    width: 40%;
    max-width: 310px;
    min-width: 310px;
    padding-top: 1.2rem;
  }
  .home-wrapper {
    margin: 0;
    padding-top: 1rem;
    height: calc(100vh - 3rem);
  }

  /* TODO: Selected dynamic class. Need update.*/
  .css-15qynig-AuthenticatedApp {
    margin: 0 auto;
    padding: 2em 0;
    max-width: 840px;
    width: 100%;
  }
  /* *! swiper js internal class */
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    visibility: visible;
    bottom: 110px !important;
  }
}

@media only screen and (min-width: 993px) {
  .arrow_icons_container {
    display: flex;
  }
}

/* *! start:swiper js internal class*/
.swiper-button-disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.swiper-pagination-bullet-active {
  background-color: rgb(255, 255, 255) !important;
}
.swiper-button-lock {
  display: block !important;
}
/* *! end:swiper js internal class*/

.no-select {
  user-select: none;
}

.container_position {
  position: relative;
}

.multine-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.bg-tapestry {
  background-color: var(--tapestry);
}

.bg-tapestry-dark {
  background-color: var(--tapestry);
}

.bg-tapestry-dark-2 {
  background-color: hsl(336, 35%, 55%);
}

.bg-transparent {
  background-color: transparent !important;
}
