.card_modal_title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  color: #1f1f1f;
  font-weight: 400;
  flex: 1;
  display: flex;
  margin: 0;
}

.slideout__close {
  background: none;
  border: 0;
  height: 53px;
  z-index: 1;
}

.back_drop {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  overflow-y: auto;
}

.card_modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  max-width: 700px;
  padding: 32px 48px;
  z-index: 1000000;

  @media screen and (max-width: 580px) {
    padding: 10px;
  }
}

.card_modal_header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 16px 0 rgb(0 0 0 / 5%);
  z-index: 1;
  max-width: 488px;
  padding-inline: 20px;
}

.card_modal_scroll_area {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.card_modal_content {
  padding: 10px 0;
}

.card_modal_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1eee1;
  background: #fff;
  z-index: 1;
}

.btn-large {
  min-width: 100px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none !important;
}

.drawer_header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.drawer_container_bg {
  background: #f4f7f5;
}

.border_top_light {
  border-top: 1px solid #e1eee1;
}

.border_bottom_light {
  border-bottom: 1px solid #e1eee1;
}

.content_container {
  padding-bottom: 120px !important;
}
