.label {
  margin: 0;
  padding: 5px;
}

.item {
  cursor: pointer;
  color: #fff;
  margin: 0;
  margin-top: 2px;
  padding: 6px 5px;
  &:hover {
    background: hsl(336, 35%, 38%);
    border-radius: 3px;
  }
}

.selected {
  background: hsl(336, 35%, 38%);
  border-radius: 3px;
}
