@import url("../font/font.css");

body {
  font-family: "GreycliffCF-Regular", cursive;
  background-image: url("../assest/percayso-clouds.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

*:focus-visible {
  outline: none;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

a:not(:hover),
a:not(:focus) {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

.table {
  color: var(--white) !important;
}

.pagination-item {
  color: var(--white) !important;
}

@media only screen and (max-width: 992px) {
  body {
    font-family: "GreycliffCF-Regular", cursive;
    background-image: url("../assest/percayso-clouds.jpg");
    /* background-size: cover !important; */
    background-position: center !important;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.vis-tooltip {
  color: var(--white) !important;
  background-color: var(--tapestry) !important;
  border: 0 !important;
}
