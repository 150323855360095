.chart-container {
  position: relative;
  width: 95%;
  height: 95%;
  margin: auto;
}
.chart-box {
  width: 100%;
  height: 100%;
}
.close-btn {
  position: absolute;
  top: 0px;
  right: 16px;
  z-index: 99999;
  font-size: 24px;
  color: var(---white);
  &:hover {
    color: var(--black);
  }
}
.title {
  position: absolute;
  top: 16px;
  left: 18px;
  z-index: 99999;
  font-size: 24px;
  color: var(---white);
  &:hover {
    color: var(--black);
  }
}
