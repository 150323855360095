.form-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.input-search-form {
  background-color: var(--tapestry) !important;
  border: none;
  color: var(--white) !important;
  height: 2.9rem;
  font-size: 1rem;
  font-family: "GreycliffCf-Medium";
  width: 100%;
}
.input-search-form:focus {
  background-color: var(--tapestry);
  outline: none !important;
}
.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.outer-search-wrapper {
  width: 50%;
  margin: auto;
}

.check-wrapper {
  width: 100%;
  margin: auto;
  max-width: 960px;
  padding-bottom: 100px;
}

.check-wrapper .outer-search-wrapper {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .outer-search-wrapper {
    width: 100%;
    margin: auto;
  }
  .check-wrapper {
    width: 100%;
    margin: auto;
  }
  .input-search-form {
    font-size: 0.9rem;
  }
}

.search-error {
  border: 1px solid var(--golden-tainoi);
}

.resultsDropDown {
  position: absolute;
  top: 43px;
  height: fit-content;
  width: 100%;
  background-color: var(--white);
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.result {
  padding: 8px 10px;
  border-bottom: 1px solid var(--silver);
  margin: 0;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  display: block;
  color: var(--black);
}

.result:last-of-type {
  border-bottom: none;
  border-radius: 0.25rem;
}

.result:hover {
  background-color: var(--silver);
  cursor: pointer;
  text-decoration: none;
  color: var(--mine-shaft);
}

.search {
  margin: 32px 0 0 0;
  background-color: var(--tawny-port) !important;
  font-size: 1rem;
  padding: 6px 12px;
  min-height: 38px;
  min-width: 78px;
}

.search-btn:hover {
  cursor: not-allowed;
}

.back-detailPage {
  color: var(--white);
  background-color: var(--mine-shaft);
  border: none;
  padding: 7px 30px;
  border-radius: 5px;
}

.back-detailPage:hover {
  cursor: pointer;
  filter: brightness(0.7);
}

.table-card {
  background-color: var(--tapestry);
  width: 100%;
  overflow: scroll;
  margin: auto;
  font-size: 0.9em;
  text-transform: capitalize;
  padding: 1rem;
  box-shadow: rgba(240, 233, 233, 0.2) 0 2px 8px 0;
}

.table-card::-webkit-scrollbar {
  display: none;
}

.table-card {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

td {
  overflow-wrap: anywhere;
  text-transform: capitalize;
}

tbody tr {
  cursor: pointer;
}

.companyId {
  overflow-wrap: break-word;
}

.btn-view-offer {
  font-size: 0.8rem;
  outline: none;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
}
.btn-view-offer:hover {
  background-color: var(--tapestry-deep);
  color: var(--white);
}
.tab-style {
  color: var(--white) !important;
}

.nav-tabs {
  border-bottom: 1px solid var(--geyser);
  color: var(--white) !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-family: "GreycliffCf-Medium";
  color: var(--white);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.nav-link:hover {
  display: block;
  padding: 0.5rem 1rem;
  font-family: "GreycliffCf-Medium";
  border: 1px solid var(--geyser);
  color: var(--white);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media only screen and (max-width: 480px) {
  .search-wrapper {
    padding: 2rem 1rem 0;
  }
}

.company-info {
  color: var(--white);
  padding: 1rem;
}

.company-info .name {
  font-weight: bold;
  font-size: 48px;
  margin: 0;
}

.company-info p {
  font-size: 19px;
  margin: 0;
}

.company-info p {
  font-size: 24px;
}
