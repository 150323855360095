.nav-icons {
  height: 1.4rem;
}
.nav-menu-icon {
  width: 25px;
  height: 25px;
  opacity: 1;
  cursor: pointer;
}

a[href="/robots"] .nav-menu-icon {
  width: 28px;
  height: 28px;
}

.selected-link {
  position: relative;
  display: flex;
}

.selected-icon {
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--white);
}

.logout {
  position: absolute;
  right: 10px;
  top: 10px;
}

.nav_search_icon {
  position: absolute;
  right: 90px;
  top: 10px;
}
