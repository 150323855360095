@font-face {
  font-family: "GreycliffCF-Regular";
  src: url("./Greycliff-CF-Regular.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Bold";
  src: url("./Greycliff-CF-Bold.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-ExtraBold";
  src: url("./Greycliff-CF-Extra-Bold.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Heavy";
  src: url("./Greycliff-CF-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCf-Medium";
  src: url("./Greycliff-CF-Medium.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Light";
  src: url("./Greycliff-CF-Light.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Demi-Bold";
  src: url("./Greycliff-CF-Demi-Bold.otf") format("opentype");
}
