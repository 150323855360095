:root {
  --black: #000;
  --white: #fff;
  --lily: #c5a6bf;
  --tapestry: #ae587a;
  --tapestry-deep: #a35297;
  --silver: #cccccc;
  --mine-shaft: #333;
  --tawny-port: #742952;
  --alto: #ddd;
  --rajah: #f6c26a;
  --cosmic: #7c3a74;
  --violet-red: #fa4879;
  --koromiko: #fec56a;
  --golden-tainoi: #ffcb6a;
  --geyser: #dee2e6;
  --hopbush: #c86ea0;
  --french-rose-deep: #f35877;
  --madison: #0c3069;
}
