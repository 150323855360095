.react-chatbot-kit-chat-container {
    position: relative;
    bottom: 0;
    right: 0;
    font-size: 1rem;
    font-family: "GreycliffCf-Medium";
}

.react-chatbot-kit-chat-header {
    background-color: rgb(174, 88, 122);
    color: #fff;
}

.react-chatbot-kit-chat-input-form {
    background-color: rgb(174, 88, 122);
    color: #fff;
}

.react-chatbot-kit-chat-input {
    background: #ae587a;
    color: #fff;
}

.react-chatbot-kit-chat-input::placeholder {
    color: #fff;
}

.app-chatbot-button {
    width: 75px;
    height: 75px;
    border-radius: 30px;
    padding: 10px;
    border: none;
    position: fixed;
    bottom: 25px;
    z-index: 9999;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease-in-out;
    background: transparent;
  }
  .app-chatbot-button:hover {
    transform: scale(1.1);
  }
  .app-chatbot-container {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 40px;
    bottom: 55px;
    z-index: 9999;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
    border-radius: 5px;
  }
  .app-chatbot-button-icon {
    fill: #fff;
  }
  