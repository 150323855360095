.euiDataGridHeader,
.euiDataGrid__content,
.euiDataGrid--bordersNone .euiDataGrid__controls,
.euiDataGridRow,
.euiDataGridRowCell__contentByHeight + .euiDataGridRowCell__expandActions {
  background: transparent !important;
  font-feature-settings: normal !important;
}

.euiDataGrid--bordersNone.euiDataGrid--headerUnderline .euiDataGridHeaderCell,
.euiDataGridRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.euiButtonEmpty__text {
  color: #fff;
  font-weight: 500;
  font-family: "GreycliffCF-Regular", cursive !important;
}

.euiPaginationButton-isActive.euiPaginationButton-isActive,
.euiPaginationButton-isActive.euiPaginationButton-isActive .euiButtonEmpty__text {
  color: rgba(255, 255, 255, 0.7) !important;
  font-family: "GreycliffCF-Regular", cursive !important;
  font-weight: 600;
}

.euiDataGrid--rowHoverHighlight .euiDataGridRow:hover {
  background: rgba(0, 0, 0, 0.3) !important;
}

.euiDataGridRowCell__truncate,
.euiDataGridHeaderCell__content,
.euiDataGridRowCell__definedHeight {
  color: #fff !important;
  font-family: "GreycliffCF-Regular", cursive !important;
}

.euiDataGridRowCell:focus::after,
.euiDataGridHeaderCell:focus::after,
.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn):focus-within::after {
  border: 2px solid hsla(100, 4%, 80%) !important;
}

.euiDataGrid--fullScreen {
  background-color: transparent !important;
  background-image: url("../assest/percayso-clouds.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  min-height: 100vh;
}

.euiButtonEmpty:hover:not(:disabled),
.euiButtonEmpty:focus {
  text-decoration-color: #fff !important;
}

.euiDataGrid--fullScreen .euiDataGrid__pagination,
.euiDataGrid--fullScreen .euiDataGridHeader {
  background: #212529 !important;
}

.euiButtonEmpty svg,
.euiDataGridHeaderCell__button svg,
.euiButtonIcon svg {
  fill: #fff !important;
}

.euiButtonIcon:is(:disabled) svg {
  fill: hsl(0, 0%, 70%) !important;
}
