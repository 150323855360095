.top-bar {
  border-top: 4px solid #58aae0;
}

.brand-logo {
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  min-height: 177px;

  img {
    width: 100px;
  }

  .rating {
    img {
      width: 90px !important;
      margin-top: 1rem;
    }
  }
}

.expert_rating {
  img {
    width: 150px !important;
    margin-top: 1rem;
  }
}

.brand-price-info {
  border-bottom: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;

  .price-details {
    min-height: 90px;
    background-color: #d4d4d4;
    width: 100%;
    padding: 1rem;

    h4 {
      font-size: 1.2rem;
      line-height: 1.125rem;
      margin-top: -0.125rem;
      margin-bottom: 0.375rem;
    }
  }

  .price--large {
    font-size: 1.875rem;
  }
  .price__pounds {
    vertical-align: baseline;
  }
  .price__pence {
    font-size: 0.6em;
    vertical-align: super;
  }
}

.top-bar .card-body {
  padding-top: 0 !important;
}

.excess_item {
  .excess_info {
    text-decoration: underline;
    font-weight: bold;
  }
  .accordion {
    border: 0 !important;
  }
  .accordion__button {
    padding: 0 !important;
    background-color: transparent !important;
  }
}

.features_icon {
  width: 16px;
  height: 16px;
  margin-right: 1rem;
}

.title_xl {
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-top: -0.375rem;
  margin-bottom: 1.625rem;
  font-weight: 700;
}

.title_sm {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: -0.375rem;
  margin-bottom: 1.125rem;
  font-weight: 700;
}

.detail-container {
  margin-bottom: 32px;
}

.detail {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: -0.375rem;
  margin-bottom: 1.125rem;
  display: flex;

  .detail__heading,
  .detail__value {
    flex-basis: 50%;
  }
}

p {
  font-size: 16px;
}

.phone {
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 2.125rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.telephone-number-link:link,
.telephone-number-link:visited,
.telephone-number-link:active,
.telephone-number-link:hover,
.dark-link:link,
.dark-link:visited,
.dark-link:active,
.dark-link:hover {
  color: #1f1f1f;
  text-decoration: underline;
}
